<template>
  <div id="PaymentCode">
    <van-nav-bar
      z-index="10"
      :fixed="true"
      placeholder
      :title="$t('My.PersonalInformation.Password.title')"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-notice-bar color="#333" background="#eee">
      {{ $t('My.PersonalInformation.Password.PleaseEnter') }}
    </van-notice-bar>
    <!-- <van-field label-width="8rem"  colon  v-model="DataArr.login" :label="$t('My.PersonalInformation.Password.LoginPassword')" :placeholder="$t('My.PersonalInformation.Password.LoginPasswordNote')" /> -->
    <van-field
      label-width="4.5rem"
      colon
      v-model="DataArr.Phone"
      :label="$t('My.PersonalInformation.Password.phone')"
      :placeholder="$t('My.PersonalInformation.Password.NewPhone')"
    />
    <van-field
      label-width="7rem"
      colon
      v-model="DataArr.Code"
      :label="$t('My.PersonalInformation.Password.verificationCode')"
      :placeholder="$t('My.PersonalInformation.Password.verificationCodeNote')"
    />
    <van-field
      label-width="4.5rem"
      colon
      v-model="DataArr.Password"
      :label="$t('My.PersonalInformation.Password.ConfirmPassword')"
      :placeholder="$t('My.PersonalInformation.Password.ConfirmPasswordNote')"
    />
    <div class="button-confirm">
      <div class="text">{{ $t('My.PersonalInformation.Password.Text') }}</div>
      <!-- <img class="code-img" v-if="codeImg" :src="codeImg" @click="ButtomCode" /> -->

      <van-button
        size="normal"
        type="default"
        block
        :disabled="isDisabled"
        @click="ButtomCode()"
        >{{ DataArr.buttonName }}</van-button
      >
      <van-button size="normal" type="default" block @click="ButtomEvent()">{{
        $t('My.PersonalInformation.Password.Button')
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { getNewPhone } from '@/utils/tools'
import Modelu from '@/utils/Module'
import { REQUEST_API } from '@/http/api' // 引入封装的axios
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  NoticeBar,
  Field,
  Toast,
} from 'vant'

export default {
  name: 'PaymentCode',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [Toast.name]: Toast,
  },
  data() {
    return {
      isDisabled: false, // 验证码按钮
      DataArr: {
        Phone: '',
        Code: '',
        Password: '',
        buttonName: this.$t('My.PersonalInformation.Password.ButtonCode'), // 按钮的提示
        time: 60,
      },
      codeImg: '',
    }
  },
  created() {
    this.ButtomCode()
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1)
    },
    // 获取验证码
    ButtomCode() {
      if (this.DataArr.Phone != '') {
        let me = this
        // 获取验证码的请求
        let formdata = {
          // phone: this.DataArr.Phone,
          phone: getNewPhone(this.DataArr.Phone),
          type: 1,
          cmd: '100002',
          /**
           * TO DO
           * 后面新增的
           */
          channel: Modelu.signStrFunc(),
          xsend: Modelu.getXsend(),
        }
        REQUEST_API(formdata).then((data) => {
          console.log(data)
          if (data.data.sc == 0) {
            /**
             * TO DO
             * 现在没有图形验证码了
             */
            this.codeImg = `data:image/jpg;base64,${data.data.notifyList[0].data.code}`
            me.isDisabled = true
            let interval = window.setInterval(function () {
              me.DataArr.buttonName = me.DataArr.time
              --me.DataArr.time
              if (me.DataArr.time < 0) {
                me.DataArr.buttonName = me.$t(
                  'My.PersonalInformation.Password.ButtonCode'
                )
                me.DataArr.time = 60
                me.isDisabled = false
                window.clearInterval(interval)
              }
            }, 1000)
          }
        })
      } else {
        Toast(this.$t('My.PersonalInformation.Password.verifyPhone'))
      }
    },
    // 提交的
    ButtomEvent() {
      if (
        this.DataArr.Phone != '' &&
        this.DataArr.Code != '' &&
        this.DataArr.Password != ''
      ) {
        let formData = {
          cmd: '200006',
          nickname: this.Name,
          uid: this.module.UIDEvent(),
          phone: this.DataArr.Phone,
          code: this.DataArr.Code,
          pay_pwd: this.DataArr.Password,
        }
        REQUEST_API(formData)
          .then((resp) => {
            if (resp.data.sc == 0) {
              Toast(this.$t('Hint.success'))
              this.$router.go(-1)
            } else {
              Toast(this.$t('Hint.failed'))
            }
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        Toast(this.$t('My.PersonalInformation.Password.PhoneNew'))
      }
    },
  },
}
</script>

<style lang="less">
#PaymentCode {
  width: 100%;
  height: 100%;
  .van-cell {
    .van-cell__title {
      > span {
        font-size: 0.875rem;
      }
    }
  }
  .button-confirm {
    width: 100%;
    margin-top: 1.5625rem;
    > .text {
      color: red;
      width: 90%;
      margin: 0 auto;
      font-size: 0.75rem;
      margin-bottom: 1.5625rem;
    }
    .code-img {
      width: 90%;
      // height: calc(44rem / 16);
      height: calc(80rem / 16);
      margin: calc(15rem / 16) auto;
      display: flex;
    }
    .van-button {
      width: 90%;
      margin: 0 auto;
      border-radius: 0.5rem;
      background-color: #2f3848;
      color: #ffffff;
    }
  }
}
</style>